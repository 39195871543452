import * as React from 'react';

import { getTranslatedLabel } from './../translations/provider';

interface Props {
  achievements: any;
}




export const AchievementList = (props: Props) => (
  <div>
    <h4 className="resume-timeline-item-desc-heading font-weight-bold achievements">{getTranslatedLabel('ACHIEVEMENTS')}</h4>
    <div dangerouslySetInnerHTML={{__html: props.achievements }} />
  </div>
);
