// tslint:disable: jsx-no-lambda
import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import { CertificationList } from './../components/CertificationList';
import { EducationList } from './../components/EducationList';
import { Header } from './../components/Header';
export { Head } from "./../components/MetaTags";
import { LanguageList } from './../components/LanguageList';
import { OtherSkillList } from './../components/OtherSkillList';
import { Project, Props as ProjectProps } from './../components/Project';
import { ResumeSkillList } from './../components/ResumeSkillList';
import { TabSelector } from './../components/TabSelector';
import { getTranslatedLabel, initLocale } from './../translations/provider';
import './CV.css';
const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();
const Lines = require('../../src/assets/images/backgrounds/lines.png');
const Resume = require('../../src/assets/files/Mark_W_Breneman_Resume_2023.pdf');


let scrollTo = 0;

interface Props {
  location: any;
  data: any;
  pageContext: any;
}


const scrollToY = () => {
  if (window && scrollTo !== 0) {
    window.scrollTo(0, scrollTo);
  }
};

const saveScrollPosition = () => {
  if (document) {
    scrollTo = document.documentElement.scrollTop;
  }
};

export default (props: Props) => {
  initLocale(props.pageContext.locale);

    const isWorkSelected = () => {
      return (
        (!props.location.pathname.includes('work') && !props.location.pathname.includes('portfolio')) ||
        props.location.pathname.includes('work')
      );
    };

    const [selectedItem, setSelectedItem] = React.useState(isWorkSelected() ? 0 : 1);
    const [items, setItems] = React.useState([
      {
        name: getTranslatedLabel('WORK_XP'),
        path: 'work',
        checked: isWorkSelected(),
        icon: 'fas fa-briefcase',
      },
      {
        name: getTranslatedLabel('OPENSOURCE'),
        path: 'portfolio',
        checked: props.location.pathname.includes('portfolio'),
        icon: 'fas fa-project-diagram',
      },
    ]);

  React.useEffect(() => {
    scrollToY();
  }, [props.location.pathname]);

  const onLanguageClick = (pathname: string) => {
    saveScrollPosition();
    pathname.includes('/de/')
      ? navigate(`/en/${items[selectedItem].path}`)
      : navigate(`/de/${items[selectedItem].path}`);
  };
 
  return (
    <div className="container">
      <article className="resume-wrapper text-center position-relative">
        <div className="resume-wrapper-inner mx-auto text-left bg-white shadow-lg">
          <Header
            profile={props.data.profile.childImageSharp.fluid}
            role={props.data.social.nodes[0].childSocialJson.role}
            name={props.data.social.nodes[0].childSocialJson.name}
            phone={props.data.social.nodes[0].childSocialJson.phone}
            email={props.data.social.nodes[0].childSocialJson.email}
            socialMedia={{
              github: props.data.social.nodes[0].childSocialJson.social.github,
              linkedin: props.data.social.nodes[0].childSocialJson.social.linkedin,
              website: props.data.social.nodes[0].childSocialJson.social.website,
              xing: props.data.social.nodes[0].childSocialJson.social.xing,
            }}
          />
          <div className="resume-body p-5 backgroundimg" style={{ backgroundImage: `url(${Lines.default})`, overflow: 'hidden' }}>
            <div className="text-right download-link">  <a href={Resume.default} title="Download Resume" ><i className="fa fa-download"></i> Download Resume</a>
            </div>

            <section className="resume-section summary-section mb-5">
              <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">
                {getTranslatedLabel('CAREER_SUMMARY')}
              </h2>
              <div className="resume-section-content" dangerouslySetInnerHTML={{ __html: props.data.summary.nodes[0].body }} />
         
            </section>

            <div className="row">
              <div className="col-lg-90">
                <TabSelector
                  items={items}
                  onClick={(index) => {
                    saveScrollPosition();
                    setSelectedItem(index);
                    navigate(`/${props.pageContext.locale}/${items[index].path}`);
                  }}
                />
                <div className="mb-3" />
                {items[0].checked && (
                  <section className="resume-section experience-section mb-5 projects">
                    <div className="resume-section-content">
                      <div className="resume-timeline position-relative">
                        {props.data.projects.nodes[0].childProjectsJson.projects.map(
                          (project: ProjectProps, index: number) => (
                            <Project key={index} {...project} />
                          )
                        )}
                      </div>
                    </div>
                  </section>
                )}
                {items[1].checked && (
                  <section className="resume-section experience-section mb-5 portfolio">
                    <div className="resume-section-content">
                      <div className="resume-timeline position-relative">
                        {props.data.portfolio.nodes[0].childPortfolioJson.projects.map(
                          (project: ProjectProps, index: number) => (
                            <Project key={index} {...project} />
                          )
                        )}
                      </div>
                    </div>
                    <div className="my-5 text-center">
                    
                    </div>
                  </section>
                )}
              </div>
              <div className="col-lg-3-disabled" style={{ marginTop: '40px' }}>
                
                <section className="resume-section education-section mb-5">
                  <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">
                    {getTranslatedLabel('EDUCATION')}
                  </h2>
                  <div className="resume-section-content">
                    <EducationList educations={props.data.educations.nodes[0].childEducationsJson.educations} />
                  </div>
                </section>
                <section className="resume-section reference-section mb-5">
                  <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">
                    {getTranslatedLabel('CERTIFICATIONS')}
                  </h2>
                  <div className="resume-section-content">
                    <CertificationList certifications={props.data.certs.nodes[0].childCertsJson.certs} />
                  </div>
                </section>
          
                <section className="resume-section interests-section mb-5">
                  <h2 className="resume-section-title text-uppercase font-weight-bold pb-2 mb-2">
                   CORE COMPETENCIES
                  </h2>
                  
                  <div className="resume-section-content">
                    <p>Leadership | Product / Project Management | Strategic Planning | Negotiations | Communication | Mentoring | Best Practices | Continuous Improvements | Training | Digital Strategy | Documentation | Audits | Vendor Management</p>
                  </div>
                  
                </section>
                <section className="resume-section interests-section mb-5">
                  <h2 className="resume-section-title text-uppercase font-weight-bold pb-2 mb-2">
                    TECHNICAL PROFICIENCIES
                  </h2>
                  <div className="resume-section-content">
                    <p>Artificial Intelligence (AI) and Machine Learning | Digital Marketing | User Interface / User Experience (UI / UX) | Analytics | Cloud Platforms | Web / Software Development | Change Management | Software Development Lifecycle | IT / Data Systems | Requirements Assessments | Troubleshooting | Risk Identification / Mitigation | Compliance | GDPR | Analytics | Legal / Regulatory Guidelines | Microsoft / Google Office suites | Drupal | WordPress | Amazon AWS | Microsoft Azure | PHP | JavaScript | Adobe ColdFusion | Adobe Photoshop | Figma | Wrike / PM Tools | MailChimp | Marketing Cloud | Large Language Models | OpenAI / ChatGPT | Llama | Streamlit</p>
                  </div>
                </section>
                <section className="resume-section interests-section mb-5">
                  <h2 className="resume-section-title text-uppercase font-weight-bold pb-2 mb-2">
                    HOBBIES
                  </h2>
                  <div className="resume-section-content">
                    <p>Reading between 30 and 50 books a year | Spending time with my family |  Working with my hands Building guitars or other Woodworking | Playing Guitar</p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </article>
      <footer className="footer text-center pt-2">
        <small className="copyright">
         Mark W. Breneman
        </small> 
        <div id="footer"><a href="https://aloharesume.com?source=mwb"><img src="https://app.aloharesume.com/logo.png" style={{width: '70px'}}></img><br/>
Explore the premier platform for AI-generated resumes and cover letters, with a special focus on your mental well-being. <br/> Aloha Resume 

</a>
<p> </p>

</div>
      </footer>
      
    </div>
  );
};

export const query = graphql`
  query($locale: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    profile: file(relativePath: { eq: "profil.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    summary: allMdx(filter: {frontmatter: { locale: { eq: $locale }, name: { eq: "summary" } }}) {
      nodes {
      frontmatter {
        locale
        name
      }
      body
    }
    }
    certs: allFile(filter: { name: { eq: $locale }, sourceInstanceName: { eq: "certs" } }) {
      nodes {
        childCertsJson {
          certs {
            title
            description
          }
        }
      }
    }
    portfolio: allFile(filter: { name: { eq: $locale }, sourceInstanceName: { eq: "portfolio" } }) {
      nodes {
        name
        childPortfolioJson {
          id
          projects {
            idtitle
            title
            company
            description
            from
            role
            url
            to
            technologies
            achievements
            location
          }
        }
      }
    }
    projects: allFile(filter: { name: { eq: $locale }, sourceInstanceName: { eq: "projects" } }) {
      nodes {
        name
        childProjectsJson {
          id
          projects {
            idtitle
            title
            company
            description
            from
            role
            to
            technologies
            achievements
            location
          }
        }
      }
    }
    skills: allFile(filter: { name: { eq: "skills" } }) {
      nodes {
        name
        childSkillsJson {
          frontend {
            name
            xpInPercentage
          }
          backend {
            name
            xpInPercentage
          }
          others
        }
      }
    }
    social: allFile(filter: { name: { eq: "social" } }) {
      nodes {
        childSocialJson {
          email
          name
          phone
          role
          social {
            github
            linkedin
            website
            xing
          }
        }
      }
    }
    educations: allFile(filter: { name: { eq: $locale }, sourceInstanceName: { eq: "educations" } }) {
      nodes {
        childEducationsJson {
          id
          educations {
            from
            titel
            to
            uni
          }
        }
      }
    }
  }
`;
