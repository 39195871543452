import * as React from 'react';

export function Head(data) {
    
    //console.log(data.data.site.siteMetadata);
    
  return (
      <>
    <title>{data.data.site.siteMetadata.title}</title>
     <meta name="description" content={data.data.site.siteMetadata.description} />
      <meta name="twitter:title" content={data.data.site.siteMetadata.title} />
      <meta name="twitter:url" content={data.data.site.siteMetadata.siteUrl} />
      <meta name="twitter:description" content={data.data.site.siteMetadata.description} />
      </>
)
}